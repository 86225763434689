.temp-photo-item {
  margin-right: 17px;
  margin-bottom: 20px;
  .item-top {
    width: 350px;
    height: 210px;
    background: rgba(249, 249, 249, 1);
    box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.08);
    display: flex;

    div {
      background: rgba(239, 242, 245, 1);
      border: 1px solid rgba(208, 231, 255, 1);
    }
    .photo {
      width: 210px;
      height: 210px;
      text-align: center;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      span {
        position: absolute;
        width: 60px;
        height: 30px;
        text-align: center;
        font-size: 12px;
        color: #fff;
        line-height: 30px;
        left: 0px;
        top: -1px;
        border-radius: 5px;
      }
    }
    .msg {
      width: 140px;
      height: 210px;
      padding: 10px;
      font-size: 12px;
      line-height: 20px;
      background: #fff;
      p {
        margin-bottom: 0;
      }

      .mark {
        display: inline-block;
        color: #999999;
        text-align: right;
      }
      
      .content {
        color: #333333;
      }

      .position-info {
        width: 100%;
        max-height: 40px;
        border: none;
        background-color: #fff;
        .mark {
          float: left;
        }
      }

      .pos-box {
        display: inline-block;
        width: 80px;
        max-height: 40px;
        float: left;
        border: none;
        .pos-content {
          border: none;
          width: 100%;
          max-height: 40px;
          word-break: break-all;
          overflow:hidden; 
          text-overflow:ellipsis; 
          display:-webkit-box; 
          /* autoprefixer: ignore next */
          -webkit-box-orient:vertical; 
          -webkit-line-clamp:2;
          background-color: #fff;
        }
      }
      
    }
  }
  .type {
    display: flex;
    align-items: center;
    height: 40px;
    background: #fff;
    div {
      width: 50%;
      text-align: center;
      line-height: 40px;
      border: #ddd;
    }
    div:nth-child(1) {
      border-right: 1px solid #ddd;
    }
  }
}


