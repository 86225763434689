.task-management {
  .task-management-main {
    // background: #ffffff;
    // padding:30px;
    // margin: 30px;
    border-radius: 6px;
    .filter {
      margin-bottom: 10px;
      > div {
        margin-right: 10px;
      }
    }
  }
  .ant-table-pagination.ant-pagination {
    float: none;
  }
  .map-container {
    height: 700px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .task-name {
    box-sizing: border-box;
    width: 100%;
    height: 21px;
    line-height: 21px;
    padding-top: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .task-state {
    display: inline-block;
    width: 90px;
  }
  .ant-tabs {
    width: 40px;
    position: relative;
    z-index: 99999;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }
  .ant-tabs .ant-tabs-left-content {
    height: 100%;
  }
  .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
    color: #fff;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    font-weight: 600;
  }
  .floors {
    position: relative;
    z-index: 10;
    height: 100%;
  }
  .ant-tabs {
    position: relative;
  }

  .title-component {
    .main-title{
      margin-right: 5px;
      font-weight: bold;
    }
    .vice-title{
      font-size: 12px;
      color: #a2a2a2;
    }
  }
  .info-elem{
    margin-bottom: 20px;
    .info-input {
      width: calc(100% - 90px);
    }
    .info-hint {
      display: inline-block;
      width: 90px;
      vertical-align:top;
    }
    .sceneHint {
      margin-left: 10px;
    }
    .first-trial-result {
      float: right;
      .elem {
        margin-right: 10px;
      }
      .hint-name {
        background-color: #f7f7f7;
        border: 1px solid #cccccc;
      }
    }
  }

  .task-name-item {
    display: inline-block;
    width: 160px;
  }

  .city {
    display: inline-block;
    width: 60px;
    
  }

  .district {
    display: inline-block;
    width: 80px;
  }

  .scene {
    display: inline-block;
    width: 40px;
  }

  .gathering {
    display: inline-block;
    width: 100px;
  }

  .controller {
    display: inline-block;
    width: 150px;
  }

  .time-item {
    display: inline-block;
    width: 80px;
  }

  .task-tags {
    display: inline-block;
    width: 100px;
  }

  p {
    margin-bottom: 0;
    word-break: break-all;
  }

  .circle {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    margin-right: 8px;
  }

  .ant-table-thead > tr > th, 
  .ant-table-tbody > tr > td {
    padding: 16px 0px;
  }
}
