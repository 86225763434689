.task-online {
    padding: 10px;
    $header-box-padding: 21px;
    .report-box{
      background-color: #ffffff;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .table-card{
        .ant-card-body{
            padding: 0;
        }
    }
    .header-box {
      background-color: #f0f2f5;
      margin-top: 10px;
      .filter {
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 24px;
        overflow: hidden;
        overflow-x: unset;
        
        .item {
            width: 266px;
            height: 188px;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            margin: 0 12px;
            padding: 10px 20px;
            cursor: pointer;
            border: 1px solid #ddd;
            background-color: #ffffff;
            justify-content: center;
            .name {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                span {
                   
                    font-size: 14px;
                    line-height: 20px;
                }

                img {
                    width: 18px;
                    height: 18px;
                }
            }

            .count {
                font-size: 30px;
                line-height: 42px;
            }

            .trend {
                display: flex;
                flex-direction: column;
                margin-top: 30px;

                .trend-item {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px 0;

                    &:nth-child(1) {
                        border-top: 1px dashed #ddd;
                    }

                    .trend-name {
                        font-size: 12px;
                        line-height: 18px;
                        
                    }

                    .trend-data {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;

                        span {
                            font-size: 14px;
                            line-height: 20px;
                        }

                        img {
                            width: 9px;
                            height: 6px;
                            margin: 0 10px;
                        }
                    }
                }
            }
        }

       

        .prohibit {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
    }
    .popup-box {
      border-bottom: 1px dashed #ccc;
      padding-top: 10px;
      .input-item {
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
        margin-bottom: 10px;
        .mark,
        .input-box {
          display: inline-block;
          vertical-align: middle;
        }
        .input-box {
          width: 150px;
        }
      }
    }
  
    .group-check-box {
      .states-header,
      .check-box-group {
        display: inline-block;
        vertical-align: top;
      }
  
      
  
      .states-header {
        width: 100px;
        margin-right: 10px;
      }
  
      .check-box-group {
        width: calc(100% - 140px);
        .ant-checkbox-group-item {
          margin-bottom: 10px;
        }
      }
      .header-mark {
        margin-right: 10px;
      }
    }
    .select-item,
    .time-item {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 10px;
    }
  
    .mark {
      margin-right: 10px;
    }
  
    .control-box {
      padding: 10px $header-box-padding;
      .btn:first-child {
        margin-right: 10px;
      }
    }
  
    .check-condition-box {
      display: inline-block;
      float: right;
      .mark,
      .check-condition {
        display: inline-block;
        vertical-align: middle;
      }
    }
  
    .task-state {
      display: inline-block;
      width: 90px;
    }
  
    .circle {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      margin-right: 8px;
    }
  
    .controller {
      display: inline-block;
      width: 150px;
  
      a {
        margin-right: 10px;
      }
  
      a:last-child {
        margin-right: 0;
      }
    }
  
  
    .scene {
      display: inline-block;
      width: 40px;
    }
  }