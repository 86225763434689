.temp-photo-item {
  margin-right: 17px;
  margin-bottom: 20px; }
  .temp-photo-item .item-top {
    width: 350px;
    height: 210px;
    background: #f9f9f9;
    box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.08);
    display: flex; }
    .temp-photo-item .item-top div {
      background: #eff2f5;
      border: 1px solid #d0e7ff; }
    .temp-photo-item .item-top .photo {
      width: 210px;
      height: 210px;
      text-align: center;
      position: relative; }
      .temp-photo-item .item-top .photo img {
        width: 100%;
        height: 100%;
        cursor: pointer; }
      .temp-photo-item .item-top .photo span {
        position: absolute;
        width: 60px;
        height: 30px;
        text-align: center;
        font-size: 12px;
        color: #fff;
        line-height: 30px;
        left: 0px;
        top: -1px;
        border-radius: 5px; }
    .temp-photo-item .item-top .msg {
      width: 140px;
      height: 210px;
      padding: 10px;
      font-size: 12px;
      line-height: 20px;
      background: #fff; }
      .temp-photo-item .item-top .msg p {
        margin-bottom: 0; }
      .temp-photo-item .item-top .msg .mark {
        display: inline-block;
        color: #999999;
        text-align: right; }
      .temp-photo-item .item-top .msg .content {
        color: #333333; }
      .temp-photo-item .item-top .msg .position-info {
        width: 100%;
        max-height: 40px;
        border: none;
        background-color: #fff; }
        .temp-photo-item .item-top .msg .position-info .mark {
          float: left; }
      .temp-photo-item .item-top .msg .pos-box {
        display: inline-block;
        width: 80px;
        max-height: 40px;
        float: left;
        border: none; }
        .temp-photo-item .item-top .msg .pos-box .pos-content {
          border: none;
          width: 100%;
          max-height: 40px;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          /* autoprefixer: ignore next */
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          background-color: #fff; }
  .temp-photo-item .type {
    display: flex;
    align-items: center;
    height: 40px;
    background: #fff; }
    .temp-photo-item .type div {
      width: 50%;
      text-align: center;
      line-height: 40px;
      border: #ddd; }
    .temp-photo-item .type div:nth-child(1) {
      border-right: 1px solid #ddd; }

.photo-page {
  padding: 20px; }
  .photo-page .type-select {
    width: 160px;
    margin-right: 20px !important; }
  .photo-page .search-box {
    width: 250px;
    text-align: center; }
  .photo-page .ant-list-item {
    display: inline-block;
    vertical-align: top; }
  .photo-page .building-name {
    display: block;
    width: 100%;
    height: 30px;
    line-height: 30px;
    margin-bottom: 0; }

.component-form-header {
  border-top: 5px solid #f0f2f5;
  border-bottom: 5px solid #f0f2f5;
  height: 30px;
  background: #e1f2ff; }
  .component-form-header img {
    width: 16px;
    height: 16px;
    margin-left: 20px;
    margin-right: 10px; }
  .component-form-header span {
    margin-right: 10px; }
  .component-form-header span em {
    color: #028bfe; }
  .component-form-header span:last-child {
    color: #028bfe;
    margin-left: 10px;
    cursor: pointer; }

.component-filter-popup .select-header {
  height: 40px;
  padding: 8px 21px;
  background-color: #fff;
  position: relative;
  font-size: 12px; }
  .component-filter-popup .select-header .mark {
    line-height: 24px; }
  .component-filter-popup .select-header .show-select-btn {
    display: inline-block;
    float: right;
    cursor: pointer;
    background: #eaeaea;
    border: 1px solid #cccccc;
    padding: 0 11px;
    line-height: 24px; }

.component-filter-popup .line {
  width: calc(100% - 42px);
  height: 0;
  margin: 0 auto;
  border-bottom: 1px dashed #ccc; }

.component-filter-popup .select-box {
  padding: 8px 21px; }

.component-filter-popup .hide-box {
  display: none; }


.component-pagination {
  padding: 0 20px; }
  .component-pagination .introduce,
  .component-pagination .pagination-box {
    display: inline-block;
    vertical-align: middle; }
  .component-pagination .introduce {
    font-size: 12px; }
    .component-pagination .introduce span:first-child {
      margin-right: 20px; }
  .component-pagination .pagination-box {
    float: right; }

.task-online-modal .modal-content-box .modal-item {
  margin-bottom: 20px; }

.task-online-modal .modal-content-box .mark,
.task-online-modal .modal-content-box .content {
  display: inline-block;
  vertical-align: top; }

.task-online-modal .modal-content-box .mark {
  box-sizing: border-box;
  padding-right: 10px;
  width: 90px;
  text-align: right; }

.task-online-modal .modal-content-box .content {
  width: calc(100% - 90px); }

.component-province-select {
  width: 100%; }
  .component-province-select .select-introduction {
    padding-bottom: 10px; }
    .component-province-select .select-introduction .intro-title,
    .component-province-select .select-introduction .intro-content,
    .component-province-select .select-introduction .clear-btn {
      display: inline-block;
      vertical-align: top;
      margin-right: 10px; }
    .component-province-select .select-introduction .intro-title {
      width: 50px; }
    .component-province-select .select-introduction .clear-btn {
      width: 30px;
      color: #1890ff;
      cursor: pointer;
      margin-right: 0; }
    .component-province-select .select-introduction .intro-content {
      position: relative;
      width: calc(100% - 100px); }
    .component-province-select .select-introduction .intro-box {
      min-height: 50px;
      line-height: 32px;
      padding: 4px 11px;
      border: 1px solid #d9d9d9;
      border-radius: 4px; }
      .component-province-select .select-introduction .intro-box:hover {
        border: 1px solid #1890ff; }
      .component-province-select .select-introduction .intro-box div {
        display: inline-block;
        line-height: 14px;
        margin: 5px;
        background-color: #f0f2f5;
        border-radius: 5px;
        padding: 0 10px; }
      .component-province-select .select-introduction .intro-box span {
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        vertical-align: middle; }
      .component-province-select .select-introduction .intro-box i {
        display: inline-block;
        width: 16px;
        height: 16px;
        line-height: 16px;
        background-color: #c0c4cc;
        color: #fff;
        border-radius: 50%;
        font-size: 10px;
        margin-left: 5px;
        vertical-align: middle;
        cursor: pointer; }
        .component-province-select .select-introduction .intro-box i:hover {
          background-color: #707172; }
        .component-province-select .select-introduction .intro-box i svg {
          margin-top: 3px; }
  .component-province-select .hide-list {
    display: none; }
  .component-province-select .list-box {
    position: absolute;
    left: 0;
    bottom: -400px;
    background-color: #fff;
    z-index: 99; }
    .component-province-select .list-box .spin-list,
    .component-province-select .list-box .list {
      display: inline-block;
      float: left;
      min-width: 120px;
      height: 400px;
      font-size: 14px;
      line-height: 30px; }
      .component-province-select .list-box .spin-list .active,
      .component-province-select .list-box .list .active {
        background-color: #aeb3b7; }
        .component-province-select .list-box .spin-list .active:hover,
        .component-province-select .list-box .list .active:hover {
          background-color: #aeb3b7; }
    .component-province-select .list-box .list {
      overflow-x: hidden;
      overflow-y: scroll; }
    .component-province-select .list-box .province-item,
    .component-province-select .list-box .city-item,
    .component-province-select .list-box .district-item {
      padding: 0 10px; }
      .component-province-select .list-box .province-item:hover,
      .component-province-select .list-box .city-item:hover,
      .component-province-select .list-box .district-item:hover {
        background-color: #f0f2f5; }
    .component-province-select .list-box .partical-select {
      display: inline-block;
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      padding: 3px;
      vertical-align: middle;
      border: 1px solid #d8d5d5;
      border-radius: 2px;
      text-align: center;
      font-size: 0;
      line-height: 16px;
      cursor: pointer; }
      .component-province-select .list-box .partical-select span {
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: #1890ff; }
    .component-province-select .list-box .checkbox {
      display: inline-block;
      width: 16px;
      height: 16px;
      vertical-align: middle; }
      .component-province-select .list-box .checkbox .ant-checkbox {
        vertical-align: top; }
    .component-province-select .list-box .name-mark {
      display: inline-block;
      line-height: 16px;
      vertical-align: middle;
      margin-left: 10px;
      cursor: pointer; }
    .component-province-select .list-box .right-arrow {
      display: inline-block;
      line-height: 16px;
      vertical-align: middle; }
  .component-province-select .province-placeholder {
    line-height: 30px;
    font-size: 14px;
    color: #c1bfbf; }

.task-online {
  padding: 10px; }
  .task-online .header-box {
    background-color: #fff;
    margin-top: 10px; }
  .task-online .popup-box {
    border-bottom: 1px dashed #ccc;
    padding-top: 10px; }
    .task-online .popup-box .input-item {
      display: inline-block;
      vertical-align: middle;
      margin-right: 20px;
      margin-bottom: 10px; }
      .task-online .popup-box .input-item .mark,
      .task-online .popup-box .input-item .input-box {
        display: inline-block;
        vertical-align: middle; }
      .task-online .popup-box .input-item .input-box {
        width: 150px; }
  .task-online .group-check-box .states-header,
  .task-online .group-check-box .check-box-group {
    display: inline-block;
    vertical-align: top; }
  .task-online .group-check-box .states-header {
    width: 100px;
    margin-right: 10px; }
  .task-online .group-check-box .check-box-group {
    width: calc(100% - 140px); }
    .task-online .group-check-box .check-box-group .ant-checkbox-group-item {
      margin-bottom: 10px; }
  .task-online .group-check-box .header-mark {
    margin-right: 10px; }
  .task-online .select-item,
  .task-online .time-item {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 10px; }
  .task-online .mark {
    margin-right: 10px; }
  .task-online .control-box {
    padding: 10px 21px; }
    .task-online .control-box .btn:first-child {
      margin-right: 10px; }
  .task-online .check-condition-box {
    display: inline-block;
    float: right; }
    .task-online .check-condition-box .mark,
    .task-online .check-condition-box .check-condition {
      display: inline-block;
      vertical-align: middle; }
  .task-online .task-state {
    display: inline-block;
    width: 90px; }
  .task-online .circle {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    margin-right: 8px; }
  .task-online .controller {
    display: inline-block;
    width: 150px; }
    .task-online .controller a {
      margin-right: 10px; }
    .task-online .controller a:last-child {
      margin-right: 0; }
  .task-online .scene {
    display: inline-block;
    width: 40px; }

.release-list .ant-table-pagination.ant-pagination {
  float: none; }

.release-list .release-list-main {
  padding: 30px;
  margin: 30px;
  background: #ffffff;
  border-radius: 6px; }
  .release-list .release-list-main .btn {
    text-align: right; }

.release-list .ant-tabs {
  width: 100%; }

.task-management .task-management-main {
  border-radius: 6px; }

.task-management .ant-table-pagination.ant-pagination {
  float: none; }

.task-management .map-container {
  height: 700px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center; }

.task-management .task-name {
  box-sizing: border-box;
  width: 100%;
  height: 21px;
  line-height: 21px;
  padding-top: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.task-management .task-state {
  display: inline-block;
  width: 90px; }

.task-management .ant-tabs {
  width: 40px;
  position: relative;
  z-index: 99999;
  border-right: 1px solid rgba(255, 255, 255, 0.3); }

.task-management .ant-tabs .ant-tabs-left-content {
  height: 100%; }

.task-management .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  color: #fff; }

.task-management .ant-tabs-nav .ant-tabs-tab-active {
  font-weight: 600; }

.task-management .floors {
  position: relative;
  z-index: 10;
  height: 100%; }

.task-management .ant-tabs {
  position: relative; }

.task-management .title-component .main-title {
  margin-right: 5px;
  font-weight: bold; }

.task-management .title-component .vice-title {
  font-size: 12px;
  color: #a2a2a2; }

.task-management .info-elem {
  margin-bottom: 20px; }
  .task-management .info-elem .info-input {
    width: calc(100% - 90px); }
  .task-management .info-elem .info-hint {
    display: inline-block;
    width: 190px;
    vertical-align: middle; }
  .task-management .info-elem .sceneHint {
    margin-left: 10px; }
  .task-management .info-elem .first-trial-result {
    float: right; }
    .task-management .info-elem .first-trial-result .elem {
      margin-right: 10px; }
    .task-management .info-elem .first-trial-result .hint-name {
      background-color: #f7f7f7;
      border: 1px solid #cccccc; }

.task-management .task-name-item {
  display: inline-block;
  width: 160px; }

.task-management .city {
  display: inline-block;
  width: 60px; }

.task-management .district {
  display: inline-block;
  width: 80px; }

.task-management .scene {
  display: inline-block;
  width: 40px; }

.task-management .gathering {
  display: inline-block;
  width: 100px; }

.task-management .controller {
  display: inline-block;
  width: 150px; }

.task-management .time-item {
  display: inline-block;
  width: 80px; }

.task-management .task-tags {
  display: inline-block;
  width: 100px; }

.task-management p {
  margin-bottom: 0;
  word-break: break-all; }

.task-management .circle {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-right: 8px; }

.task-management .square {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  margin-right: 8px;
  line-height: 30px;
  color: #ffffff;
  font-size: 18px; }

.task-management .ant-table-thead > tr > th,
.task-management .ant-table-tbody > tr > td {
  padding: 16px 0px; }


.task-online {
  padding: 10px; }
  .task-online .report-box {
    background-color: #ffffff;
    margin-top: 10px;
    margin-bottom: 10px; }
  .task-online .table-card .ant-card-body {
    padding: 0; }
  .task-online .header-box {
    background-color: #f0f2f5;
    margin-top: 10px; }
    .task-online .header-box .filter {
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 24px;
      overflow: hidden;
      overflow-x: unset; }
      .task-online .header-box .filter .item {
        width: 266px;
        height: 188px;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        margin: 0 12px;
        padding: 10px 20px;
        cursor: pointer;
        border: 1px solid #ddd;
        background-color: #ffffff;
        justify-content: center; }
        .task-online .header-box .filter .item .name {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between; }
          .task-online .header-box .filter .item .name span {
            font-size: 14px;
            line-height: 20px; }
          .task-online .header-box .filter .item .name img {
            width: 18px;
            height: 18px; }
        .task-online .header-box .filter .item .count {
          font-size: 30px;
          line-height: 42px; }
        .task-online .header-box .filter .item .trend {
          display: flex;
          flex-direction: column;
          margin-top: 30px; }
          .task-online .header-box .filter .item .trend .trend-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0; }
            .task-online .header-box .filter .item .trend .trend-item:nth-child(1) {
              border-top: 1px dashed #ddd; }
            .task-online .header-box .filter .item .trend .trend-item .trend-name {
              font-size: 12px;
              line-height: 18px; }
            .task-online .header-box .filter .item .trend .trend-item .trend-data {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center; }
              .task-online .header-box .filter .item .trend .trend-item .trend-data span {
                font-size: 14px;
                line-height: 20px; }
              .task-online .header-box .filter .item .trend .trend-item .trend-data img {
                width: 9px;
                height: 6px;
                margin: 0 10px; }
      .task-online .header-box .filter .prohibit {
        cursor: not-allowed;
        opacity: 0.5; }
  .task-online .popup-box {
    border-bottom: 1px dashed #ccc;
    padding-top: 10px; }
    .task-online .popup-box .input-item {
      display: inline-block;
      vertical-align: middle;
      margin-right: 20px;
      margin-bottom: 10px; }
      .task-online .popup-box .input-item .mark,
      .task-online .popup-box .input-item .input-box {
        display: inline-block;
        vertical-align: middle; }
      .task-online .popup-box .input-item .input-box {
        width: 150px; }
  .task-online .group-check-box .states-header,
  .task-online .group-check-box .check-box-group {
    display: inline-block;
    vertical-align: top; }
  .task-online .group-check-box .states-header {
    width: 100px;
    margin-right: 10px; }
  .task-online .group-check-box .check-box-group {
    width: calc(100% - 140px); }
    .task-online .group-check-box .check-box-group .ant-checkbox-group-item {
      margin-bottom: 10px; }
  .task-online .group-check-box .header-mark {
    margin-right: 10px; }
  .task-online .select-item,
  .task-online .time-item {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 10px; }
  .task-online .mark {
    margin-right: 10px; }
  .task-online .control-box {
    padding: 10px 21px; }
    .task-online .control-box .btn:first-child {
      margin-right: 10px; }
  .task-online .check-condition-box {
    display: inline-block;
    float: right; }
    .task-online .check-condition-box .mark,
    .task-online .check-condition-box .check-condition {
      display: inline-block;
      vertical-align: middle; }
  .task-online .task-state {
    display: inline-block;
    width: 90px; }
  .task-online .circle {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    margin-right: 8px; }
  .task-online .controller {
    display: inline-block;
    width: 150px; }
    .task-online .controller a {
      margin-right: 10px; }
    .task-online .controller a:last-child {
      margin-right: 0; }
  .task-online .scene {
    display: inline-block;
    width: 40px; }

.task-management .task-management-main {
  border-radius: 6px; }
  .task-management .task-management-main .filter {
    margin-bottom: 10px; }
    .task-management .task-management-main .filter > div {
      margin-right: 10px; }

.task-management .ant-table-pagination.ant-pagination {
  float: none; }

.task-management .map-container {
  height: 700px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center; }

.task-management .task-name {
  box-sizing: border-box;
  width: 100%;
  height: 21px;
  line-height: 21px;
  padding-top: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.task-management .task-state {
  display: inline-block;
  width: 90px; }

.task-management .ant-tabs {
  width: 40px;
  position: relative;
  z-index: 99999;
  border-right: 1px solid rgba(255, 255, 255, 0.3); }

.task-management .ant-tabs .ant-tabs-left-content {
  height: 100%; }

.task-management .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  color: #fff; }

.task-management .ant-tabs-nav .ant-tabs-tab-active {
  font-weight: 600; }

.task-management .floors {
  position: relative;
  z-index: 10;
  height: 100%; }

.task-management .ant-tabs {
  position: relative; }

.task-management .title-component .main-title {
  margin-right: 5px;
  font-weight: bold; }

.task-management .title-component .vice-title {
  font-size: 12px;
  color: #a2a2a2; }

.task-management .info-elem {
  margin-bottom: 20px; }
  .task-management .info-elem .info-input {
    width: calc(100% - 90px); }
  .task-management .info-elem .info-hint {
    display: inline-block;
    width: 90px;
    vertical-align: top; }
  .task-management .info-elem .sceneHint {
    margin-left: 10px; }
  .task-management .info-elem .first-trial-result {
    float: right; }
    .task-management .info-elem .first-trial-result .elem {
      margin-right: 10px; }
    .task-management .info-elem .first-trial-result .hint-name {
      background-color: #f7f7f7;
      border: 1px solid #cccccc; }

.task-management .task-name-item {
  display: inline-block;
  width: 160px; }

.task-management .city {
  display: inline-block;
  width: 60px; }

.task-management .district {
  display: inline-block;
  width: 80px; }

.task-management .scene {
  display: inline-block;
  width: 40px; }

.task-management .gathering {
  display: inline-block;
  width: 100px; }

.task-management .controller {
  display: inline-block;
  width: 150px; }

.task-management .time-item {
  display: inline-block;
  width: 80px; }

.task-management .task-tags {
  display: inline-block;
  width: 100px; }

.task-management p {
  margin-bottom: 0;
  word-break: break-all; }

.task-management .circle {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-right: 8px; }

.task-management .ant-table-thead > tr > th,
.task-management .ant-table-tbody > tr > td {
  padding: 16px 0px; }

.select-form {
  width: calc(100% - 20px);
  margin: 0 auto;
  margin-top: 10px;
  background: #fff; }
  .select-form .filter {
    margin-bottom: 10px; }
    .select-form .filter > div {
      margin-right: 10px; }
    .select-form .filter .ant-select-selection__rendered {
      text-indent: 10px; }
  .select-form .form-box {
    transition: height 2s ease;
    -webkit-transition: height 2s ease;
    /* Safari */ }
  .select-form .hide {
    height: 0px;
    transition: height 2s ease;
    -webkit-transition: height 2s ease; }
  .select-form .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 40px;
    border-bottom: 1px dashed #cccccc;
    margin-left: 20px;
    margin-right: 20px; }
    .select-form .title .btn {
      cursor: pointer;
      width: 50px;
      height: 22px;
      background: #eaeaea;
      border: 1px solid #cccccc;
      line-height: 22px;
      text-align: center;
      margin-top: 10px; }
  .select-form .form1 {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 1px dashed #cccccc; }
    .select-form .form1 div:nth-child(1) {
      margin-left: 0px; }
    .select-form .form1 .form-item {
      line-height: 60px;
      margin-left: 26px; }
      .select-form .form1 .form-item span {
        margin-right: 10px; }
      .select-form .form1 .form-item .ant-select-selection__placeholder {
        font-size: 12px;
        /* color: #333333; */
        text-indent: 8px; }
      .select-form .form1 .form-item .ant-calendar-range-picker-input {
        font-size: 12px; }
      .select-form .form1 .form-item .ant-select-selection-selected-value {
        font-size: 12px;
        text-indent: 10px; }
  .select-form .province-box {
    margin-top: 15px;
    font-size: 14px; }
  .select-form .group-check-box {
    padding: 10px 20px 0 20px;
    border-bottom: 1px dashed #ccc; }
    .select-form .group-check-box .states-header,
    .select-form .group-check-box .check-box-group {
      display: inline-block;
      vertical-align: top; }
    .select-form .group-check-box .states-header {
      width: 100px;
      margin-right: 10px;
      margin-bottom: 10px; }
    .select-form .group-check-box .check-box-group {
      width: calc(100% - 140px); }
      .select-form .group-check-box .check-box-group .ant-checkbox-group-item {
        margin-bottom: 10px; }
    .select-form .group-check-box .header-mark {
      margin-right: 10px; }
  .select-form .export {
    display: flex;
    justify-content: space-between;
    line-height: 60px;
    margin: 10px 20px; }
    .select-form .export .btn-left {
      display: flex; }
      .select-form .export .btn-left button {
        margin-right: 10px; }
    .select-form .export .btn-right {
      display: flex; }
      .select-form .export .btn-right .selectTypeSpan {
        font-weight: bold;
        flex: none;
        line-height: 32px;
        margin-left: 20px;
        margin-right: 10px; }
  .select-form .form-head {
    border-top: 5px solid #f0f2f5;
    border-bottom: 5px solid #f0f2f5;
    height: 30px;
    background: #e1f2ff; }
    .select-form .form-head img {
      width: 16px;
      height: 16px;
      margin-left: 20px;
      margin-right: 10px; }
    .select-form .form-head span {
      margin-right: 10px; }
    .select-form .form-head span em {
      color: #028bfe; }
    .select-form .form-head span:last-child {
      color: #028bfe;
      margin-left: 10px;
      cursor: pointer; }

.shell .ant-modal {
  width: 670px !important; }

.shell .title-component .main-title {
  width: 56px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #141414;
  line-height: 20px;
  font-family: "PingFangSC-Regular"; }

.shell .info-elem {
  margin-bottom: 15px;
  margin-top: 15px; }
  .shell .info-elem .info-input {
    width: calc(95% - 90px); }
  .shell .info-elem .info-input-show {
    width: 56px;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    font-family: "PingFangSC-Regular"; }
  .shell .info-elem .info-hint {
    width: 90px;
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #4e5155;
    line-height: 20px;
    text-align: right;
    display: inline-block;
    vertical-align: top;
    font-family: "PingFangSC-Medium"; }
  .shell .info-elem .info-mark {
    margin-top: 4px; }
  .shell .info-elem .first-trial-result {
    float: right;
    margin-right: 23px; }

.shell .clear-float {
  clear: right; }

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@charset "UTF-8";
.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.pg-wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  background-color: #f0f2f5; }

.pg-left {
  height: 100%;
  position: relative;
  width: 200px;
  left: 0;
  top: 0;
  z-index: 10;
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }
  .pg-left .left-head {
    height: 60px;
    background: #00284d;
    line-height: 60px; }
  .pg-left .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background-color: #001529;
    box-shadow: none; }
  .pg-left .ant-menu-dark .ant-menu-item,
  .pg-left .ant-menu-dark .ant-menu-item-group-title,
  .pg-left .ant-menu-dark .ant-menu-item > a,
  .pg-left .ant-menu-dark,
  .pg-left .ant-menu-dark .ant-menu-sub {
    color: white; }
  .pg-left .logo-name {
    display: block;
    color: #fff;
    background-size: 25px auto;
    height: 100%;
    white-space: nowrap;
    background: #171f28; }
  .pg-left .pg-left-container {
    padding-top: 20px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: #001529;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .pg-left.fold {
    width: 0px; }
    .pg-left.fold .anticon-menu-unfold {
      display: inline-block; }
    .pg-left.fold .anticon-menu-fold {
      display: none; }
    .pg-left.fold .logo-name span {
      display: none; }
    .pg-left.fold .ant-menu-item {
      display: none; }
    .pg-left.fold .ant-menu {
      display: none; }
    .pg-left.fold .menu-link span {
      display: none; }
    .pg-left.fold .menu-link a {
      display: none !important; }
    .pg-left.fold .menu-list-main i {
      display: none; }
  .pg-left .toggle-btn {
    position: absolute;
    font-size: 20px;
    width: 60px;
    z-index: 9;
    white-space: nowrap;
    cursor: pointer;
    text-align: center;
    right: -50px; }
  .pg-left .anticon-menu-unfold {
    display: none; }

.pg-body {
  overflow-y: auto;
  height: 100%;
  background-color: #f0f2f5;
  flex: 1 1; }
  .pg-body .ant-breadcrumb {
    height: 40px;
    line-height: 40px;
    padding-left: 30px;
    background-color: #fff; }

.pg-body-inner {
  height: 100%;
  display: flex;
  flex-direction: column; }

.pg-header {
  height: 60px;
  background: #171f28;
  border-bottom: solid 1px #ddd; }
  .pg-header .logo-name,
  .pg-header .welcome,
  .pg-header .top-links li {
    height: 60px;
    line-height: 60px; }
  .pg-header .head-right {
    position: relative;
    margin-left: 200px; }
  .pg-header .welcome,
  .pg-header .top-links {
    position: absolute;
    top: 0; }
  .pg-header .welcome {
    left: 0;
    padding-left: 16px; }
  .pg-header .top-links {
    top: 20px;
    right: 0; }
    .pg-header .top-links li {
      cursor: pointer;
      display: inline-block;
      margin-right: 16px;
      height: inherit;
      line-height: inherit;
      font-size: 14px; }
      .pg-header .top-links li i {
        margin-right: 8px; }
  .pg-header + div {
    flex: 1 1;
    overflow: auto; }

.menu-list {
  white-space: nowrap; }
  .menu-list .menu-list {
    display: block; }
  .menu-list .menu-parent {
    padding-top: 8px; }
    .menu-list .menu-parent .menu-title {
      padding-left: 8px;
      font-weight: 700; }
  .menu-list .menu-children,
  .menu-list .menu-link {
    position: relative; }
    .menu-list .menu-children a,
    .menu-list .menu-children .menu-title,
    .menu-list .menu-link a,
    .menu-list .menu-link .menu-title {
      display: block;
      padding: 8px;
      color: #fff;
      font-weight: 400;
      padding-left: 10px; }
  .menu-list .menu-children .btn-add {
    position: absolute;
    top: 2px;
    right: 8px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 16px;
    cursor: pointer; }
  .menu-list .anticon {
    vertical-align: middle;
    margin-right: 5px;
    font-size: 16px; }

.main-padding {
  padding: 8px; }

.menu-list-main i {
  width: 15px;
  height: 15px;
  margin-right: 4px; }

.menu-list-main span {
  vertical-align: middle; }

.menu-list-main .active {
  color: #fff !important;
  background-color: #1890ff !important; }

.menu-list-main .menu-link {
  font-size: 14px;
  color: #fff; }
  .menu-list-main .menu-link > a {
    padding-left: 20px; }
  .menu-list-main .menu-link .menu-title {
    margin: 6px 0; }
    .menu-list-main .menu-link .menu-title > div {
      padding: 8px; }
    .menu-list-main .menu-link .menu-title > a {
      width: 100%;
      display: block;
      padding: 8px;
      color: #fff; }
  .menu-list-main .menu-link .menu-list-main .menu-link {
    margin: 6px 0; }
    .menu-list-main .menu-link .menu-list-main .menu-link a {
      width: 100%;
      display: block;
      padding: 8px;
      padding-left: 24px;
      color: #fff; }

::-webkit-scrollbar {
  width: 3px;
  height: 8px; }

::-webkit-scrollbar-track {
  background-color: #0e141b;
  border-radius: 5px; }

::-webkit-scrollbar-thumb {
  background-color: #bee1eb;
  border-radius: 5px; }

::-webkit-scrollbar-thumb:hover {
  background-color: #bee1eb;
  border-radius: 5px; }

::-webkit-scrollbar-thumb:active {
  background-color: #bee1eb;
  border-radius: 5px; }

body {
  margin: 0;
  padding: 0;
  /* moz-user-select: -moz-none;
   -moz-user-select: none;
   -o-user-select: none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none; !* 禁止页面选中 *!*/
  width: 100%;
  height: 100%;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1", Arial, sans-serif;
  font-size: 12px !important;
  overflow: hidden; }

.hide {
  display: none; }

li {
  list-style: none; }

a,
input,
select,
textarea,
button {
  outline: none; }

table {
  border-collapse: separate;
  border-spacing: 0; }

button {
  cursor: pointer; }

input[type="number"] {
  -moz-appearance: textfield; }

/* 浮动 */
.fl {
  float: left; }

.fr {
  float: right; }

/*.cf:before, */
.cf:after {
  display: block;
  content: " ";
  height: 0;
  overflow: hidden; }

.cf:after {
  clear: both; }

.h1 {
  font-size: 20px;
  font-weight: 700; }

.h2 {
  font-size: 18px;
  font-weight: 700; }

.h3 {
  font-size: 16px;
  font-weight: 700; }

.h4 {
  font-size: 14px;
  font-weight: 700; }

.h5 {
  font-size: 12px;
  font-weight: 700; }

.ant-table-wrapper {
  margin-bottom: 15px; }

.action span,
.action a {
  margin: 0 3px;
  color: #1890ff;
  cursor: pointer; }

.history-table {
  max-height: 500px;
  overflow-y: auto; }

.main-content {
  background: #ffffff;
  margin: 30px;
  padding: 30px; }
  .main-content .title {
    display: block; }
  .main-content .content {
    margin-top: 20px;
    font-size: 14px; }

.action-span {
  margin: 0 10px;
  color: #1890ff;
  cursor: pointer; }

.ant-pagination {
  text-align: center; }

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  text-align: center; }

.ant-modal-close-x {
  width: 20px;
  height: 20px;
  line-height: 20px; }

.clear-fix:after {
  display: table;
  content: '';
  clear: both; }

.photo-modal {
  text-align: center;
  font-size: 0; }
  .photo-modal .ant-modal-wrap {
    text-align: center; }
  .photo-modal .ant-modal-body {
    padding: 24px; }
  .photo-modal .ant-modal {
    width: auto !important;
    display: inline-block;
    top: 0 !important;
    padding-bottom: 0 !important; }

