.component-filter-popup {
  // position: relative;
  $select-header-height: 40px;
  .select-header {
    height: $select-header-height;
    padding: 8px 21px;
    background-color: #fff;
    position: relative;
    font-size: 12px;
    .mark {
      line-height: 24px;
    }

    .show-select-btn {
      display: inline-block;
      float: right;
      cursor: pointer;
      background: rgba(234, 234, 234, 1);
      border: 1px solid rgba(204, 204, 204, 1);
      padding: 0 11px;
      line-height: 24px;
    }
  }

  .line {
    width: calc(100% - 42px);
    height: 0;
    margin: 0 auto;
    border-bottom: 1px dashed #ccc;
  }

  .select-box {
    padding: 8px 21px;
  }

  .hide-box {
    display: none;
  }
}
