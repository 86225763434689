.task-online {
  padding: 10px;
  $header-box-padding: 21px;
  .header-box {
    background-color: #fff;
    margin-top: 10px;
  }
  .popup-box {
    border-bottom: 1px dashed #ccc;
    padding-top: 10px;
    .input-item {
      display: inline-block;
      vertical-align: middle;
      margin-right: 20px;
      margin-bottom: 10px;
      .mark,
      .input-box {
        display: inline-block;
        vertical-align: middle;
      }
      .input-box {
        width: 150px;
      }
    }
  }

  .group-check-box {
    .states-header,
    .check-box-group {
      display: inline-block;
      vertical-align: top;
    }

    

    .states-header {
      width: 100px;
      margin-right: 10px;
    }

    .check-box-group {
      width: calc(100% - 140px);
      .ant-checkbox-group-item {
        margin-bottom: 10px;
      }
    }
    .header-mark {
      margin-right: 10px;
    }
  }
  .select-item,
  .time-item {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 10px;
  }

  .mark {
    margin-right: 10px;
  }

  .control-box {
    padding: 10px $header-box-padding;
    .btn:first-child {
      margin-right: 10px;
    }
  }

  .check-condition-box {
    display: inline-block;
    float: right;
    .mark,
    .check-condition {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .task-state {
    display: inline-block;
    width: 90px;
  }

  .circle {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    margin-right: 8px;
  }

  .controller {
    display: inline-block;
    width: 150px;

    a {
      margin-right: 10px;
    }

    a:last-child {
      margin-right: 0;
    }
  }


  .scene {
    display: inline-block;
    width: 40px;
  }
}