.task-online-modal {
  $mark-width: 90px;
  .modal-content-box {
    .modal-item {
      margin-bottom: 20px;
    }
    .mark,
    .content {
      display: inline-block;
      vertical-align: top;
    }

    .mark {
      box-sizing: border-box;
      padding-right: 10px;
      width: $mark-width;
      text-align: right;
    }
    .content {
      width: calc(100% - #{$mark-width});
    }
  }
}