.select-form {
  width: calc(100% - 20px);
  margin: 0 auto;
  margin-top: 10px;
  background: #fff;
  .filter {
    margin-bottom: 10px;
    > div {
      margin-right: 10px;
    }
    .ant-select-selection__rendered {
      text-indent: 10px;
  }
  }
  .form-box {
    transition: height 2s ease;
    -webkit-transition: height 2s ease; /* Safari */
    // height: 300px;
  }
  .hide {
    height: 0px;
    transition: height 2s ease;
    -webkit-transition: height 2s ease;
  }
  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 40px;
    border-bottom: 1px dashed #cccccc;
    margin-left: 20px;
    margin-right: 20px;
    .btn {
      cursor: pointer;
      width: 50px;
      height: 22px;
      background: rgba(234, 234, 234, 1);
      border: 1px solid rgba(204, 204, 204, 1);
      line-height: 22px;
      text-align: center;
      margin-top: 10px;
    }
  }

  .form1 {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 1px dashed #cccccc;
    & div:nth-child(1) {
      margin-left: 0px;
    }
    .form-item {
      line-height: 60px;
      margin-left: 26px;
      span {
        margin-right: 10px;
      }
      .ant-select-selection__placeholder {
        font-size: 12px;
        /* color: #333333; */
        text-indent: 8px;
      }
      .ant-calendar-range-picker-input {
        font-size: 12px;
      }
      .ant-select-selection-selected-value {
        font-size: 12px;
        text-indent: 10px;
      }
    }
  }

  .province-box {
    margin-top: 15px;
    font-size: 14px;
  }

  .group-check-box {
    padding: 10px 20px 0 20px;
    border-bottom: 1px dashed #ccc; 

    .states-header,
    .check-box-group {
      display: inline-block;
      vertical-align: top;
    }


    .states-header {
      width: 100px;
      margin-right: 10px;
      margin-bottom: 10px;
    }

    .check-box-group {
      width: calc(100% - 140px);
      .ant-checkbox-group-item {
        margin-bottom: 10px;
      }
    }
    .header-mark {
      margin-right: 10px;
    }
  }



  .export {
    display: flex;
    justify-content: space-between;
    line-height: 60px;
    margin: 10px 20px;
    .btn-left {
      display: flex;
      button {
        margin-right: 10px;
      }
    }
    .btn-right {
      display: flex;
      .selectTypeSpan{
        font-weight: bold;;
        flex: none;
        line-height: 32px;
        margin-left: 20px;
        margin-right: 10px;
      }
    }
  }
  .form-head {
    border-top: 5px solid #f0f2f5;
    border-bottom: 5px solid #f0f2f5;
    height: 30px;
    background: rgba(225, 242, 255, 1);

    img {
      width: 16px;
      height: 16px;
      margin-left: 20px;
      margin-right: 10px;
    }
    span {
      margin-right: 10px;
    }
    span em {
      color: #028bfe;
    }
    span:last-child {
      color: #028bfe;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
