@charset "UTF-8";
@import url(../node_modules/antd/dist/antd.css);
.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.pg-wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  background-color: #f0f2f5; }

.pg-left {
  height: 100%;
  position: relative;
  width: 200px;
  left: 0;
  top: 0;
  z-index: 10;
  transition: all 0.5s; }
  .pg-left .left-head {
    height: 60px;
    background: #00284d;
    line-height: 60px; }
  .pg-left .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background-color: #001529;
    box-shadow: none; }
  .pg-left .ant-menu-dark .ant-menu-item,
  .pg-left .ant-menu-dark .ant-menu-item-group-title,
  .pg-left .ant-menu-dark .ant-menu-item > a,
  .pg-left .ant-menu-dark,
  .pg-left .ant-menu-dark .ant-menu-sub {
    color: white; }
  .pg-left .logo-name {
    display: block;
    color: #fff;
    background-size: 25px auto;
    height: 100%;
    white-space: nowrap;
    background: #171f28; }
  .pg-left .pg-left-container {
    padding-top: 20px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: #001529;
    user-select: none; }
  .pg-left.fold {
    width: 0px; }
    .pg-left.fold .anticon-menu-unfold {
      display: inline-block; }
    .pg-left.fold .anticon-menu-fold {
      display: none; }
    .pg-left.fold .logo-name span {
      display: none; }
    .pg-left.fold .ant-menu-item {
      display: none; }
    .pg-left.fold .ant-menu {
      display: none; }
    .pg-left.fold .menu-link span {
      display: none; }
    .pg-left.fold .menu-link a {
      display: none !important; }
    .pg-left.fold .menu-list-main i {
      display: none; }
  .pg-left .toggle-btn {
    position: absolute;
    font-size: 20px;
    width: 60px;
    z-index: 9;
    white-space: nowrap;
    cursor: pointer;
    text-align: center;
    right: -50px; }
  .pg-left .anticon-menu-unfold {
    display: none; }

.pg-body {
  overflow-y: auto;
  height: 100%;
  background-color: #f0f2f5;
  flex: 1; }
  .pg-body .ant-breadcrumb {
    height: 40px;
    line-height: 40px;
    padding-left: 30px;
    background-color: #fff; }

.pg-body-inner {
  height: 100%;
  display: flex;
  flex-direction: column; }

.pg-header {
  height: 60px;
  background: #171f28;
  border-bottom: solid 1px #ddd; }
  .pg-header .logo-name,
  .pg-header .welcome,
  .pg-header .top-links li {
    height: 60px;
    line-height: 60px; }
  .pg-header .head-right {
    position: relative;
    margin-left: 200px; }
  .pg-header .welcome,
  .pg-header .top-links {
    position: absolute;
    top: 0; }
  .pg-header .welcome {
    left: 0;
    padding-left: 16px; }
  .pg-header .top-links {
    top: 20px;
    right: 0; }
    .pg-header .top-links li {
      cursor: pointer;
      display: inline-block;
      margin-right: 16px;
      height: inherit;
      line-height: inherit;
      font-size: 14px; }
      .pg-header .top-links li i {
        margin-right: 8px; }
  .pg-header + div {
    flex: 1;
    overflow: auto; }

.menu-list {
  white-space: nowrap; }
  .menu-list .menu-list {
    display: block; }
  .menu-list .menu-parent {
    padding-top: 8px; }
    .menu-list .menu-parent .menu-title {
      padding-left: 8px;
      font-weight: 700; }
  .menu-list .menu-children,
  .menu-list .menu-link {
    position: relative; }
    .menu-list .menu-children a,
    .menu-list .menu-children .menu-title,
    .menu-list .menu-link a,
    .menu-list .menu-link .menu-title {
      display: block;
      padding: 8px;
      color: #fff;
      font-weight: 400;
      padding-left: 10px; }
  .menu-list .menu-children .btn-add {
    position: absolute;
    top: 2px;
    right: 8px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 16px;
    cursor: pointer; }
  .menu-list .anticon {
    vertical-align: middle;
    margin-right: 5px;
    font-size: 16px; }

.main-padding {
  padding: 8px; }

.menu-list-main i {
  width: 15px;
  height: 15px;
  margin-right: 4px; }

.menu-list-main span {
  vertical-align: middle; }

.menu-list-main .active {
  color: #fff !important;
  background-color: #1890ff !important; }

.menu-list-main .menu-link {
  font-size: 14px;
  color: #fff; }
  .menu-list-main .menu-link > a {
    padding-left: 20px; }
  .menu-list-main .menu-link .menu-title {
    margin: 6px 0; }
    .menu-list-main .menu-link .menu-title > div {
      padding: 8px; }
    .menu-list-main .menu-link .menu-title > a {
      width: 100%;
      display: block;
      padding: 8px;
      color: #fff; }
  .menu-list-main .menu-link .menu-list-main .menu-link {
    margin: 6px 0; }
    .menu-list-main .menu-link .menu-list-main .menu-link a {
      width: 100%;
      display: block;
      padding: 8px;
      padding-left: 24px;
      color: #fff; }

::-webkit-scrollbar {
  width: 3px;
  height: 8px; }

::-webkit-scrollbar-track {
  background-color: #0e141b;
  border-radius: 5px; }

::-webkit-scrollbar-thumb {
  background-color: #bee1eb;
  border-radius: 5px; }

::-webkit-scrollbar-thumb:hover {
  background-color: #bee1eb;
  border-radius: 5px; }

::-webkit-scrollbar-thumb:active {
  background-color: #bee1eb;
  border-radius: 5px; }

body {
  margin: 0;
  padding: 0;
  /* moz-user-select: -moz-none;
   -moz-user-select: none;
   -o-user-select: none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none; !* 禁止页面选中 *!*/
  width: 100%;
  height: 100%;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 12px !important;
  overflow: hidden; }

.hide {
  display: none; }

li {
  list-style: none; }

a,
input,
select,
textarea,
button {
  outline: none; }

table {
  border-collapse: separate;
  border-spacing: 0; }

button {
  cursor: pointer; }

input[type="number"] {
  -moz-appearance: textfield; }

/* 浮动 */
.fl {
  float: left; }

.fr {
  float: right; }

/*.cf:before, */
.cf:after {
  display: block;
  content: "\0020";
  height: 0;
  overflow: hidden; }

.cf:after {
  clear: both; }

.h1 {
  font-size: 20px;
  font-weight: 700; }

.h2 {
  font-size: 18px;
  font-weight: 700; }

.h3 {
  font-size: 16px;
  font-weight: 700; }

.h4 {
  font-size: 14px;
  font-weight: 700; }

.h5 {
  font-size: 12px;
  font-weight: 700; }

.ant-table-wrapper {
  margin-bottom: 15px; }

.action span,
.action a {
  margin: 0 3px;
  color: #1890ff;
  cursor: pointer; }

.history-table {
  max-height: 500px;
  overflow-y: auto; }

.main-content {
  background: #ffffff;
  margin: 30px;
  padding: 30px; }
  .main-content .title {
    display: block; }
  .main-content .content {
    margin-top: 20px;
    font-size: 14px; }

.action-span {
  margin: 0 10px;
  color: #1890ff;
  cursor: pointer; }

.ant-pagination {
  text-align: center; }

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  text-align: center; }

.ant-modal-close-x {
  width: 20px;
  height: 20px;
  line-height: 20px; }

.clear-fix:after {
  display: table;
  content: '';
  clear: both; }

.photo-modal {
  text-align: center;
  font-size: 0; }
  .photo-modal .ant-modal-wrap {
    text-align: center; }
  .photo-modal .ant-modal-body {
    padding: 24px; }
  .photo-modal .ant-modal {
    width: auto !important;
    display: inline-block;
    top: 0 !important;
    padding-bottom: 0 !important; }
