.photo-page {
  padding: 20px;
  .type-select {
    width: 160px;
    margin-right: 20px !important;
  }
  .search-box {
    width: 250px;
    text-align: center;
  }
  .ant-list-item {
    display: inline-block;
    vertical-align: top;
  }
  .building-name {
    display: block;
    width: 100%;
    height: 30px;
    line-height: 30px;
    margin-bottom: 0;
  }
}