.shell {
  .ant-modal {
    width: 670px !important;
  }
  
  .title-component {
    .main-title {
      width: 56px;
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(20, 20, 20, 1);
      line-height: 20px;
      font-family: "PingFangSC-Regular";
    }
  }

  .info-elem {
    margin-bottom: 15px;
    margin-top: 15px;

    .info-input {
      width: calc(95% - 90px);
    }

    .info-input-show {
      width: 56px;
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(102, 102, 102, 1);
      line-height: 20px;
      font-family: "PingFangSC-Regular";
    }

    .info-hint {
      width: 90px;
      height: 20px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(78, 81, 85, 1);
      line-height: 20px;
      text-align: right;
      display: inline-block;
      vertical-align: top;
      font-family: "PingFangSC-Medium";
    }

    .info-mark {
      margin-top: 4px;
    }

    .first-trial-result {
      float: right;
      margin-right: 23px;
    }
  }

  .clear-float {
    clear: right;
  }
}