.component-form-header {
  border-top: 5px solid #f0f2f5;
  border-bottom: 5px solid #f0f2f5;
  height: 30px;
  background: rgba(225, 242, 255, 1);

  img {
    width: 16px;
    height: 16px;
    margin-left: 20px;
    margin-right: 10px;
  }
  span {
    margin-right: 10px;
  }
  span em {
    color: #028bfe;
  }
  span:last-child {
    color: #028bfe;
    margin-left: 10px;
    cursor: pointer;
  }
}