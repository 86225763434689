$font_family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
$default_bg_color: #35b1c9;
$default_color: #fff;
$box_bg_color: #191F27;

$theme_color: #fff;
$disabled_bg_color: #eee;
$disabled_color: #ccc;
$border_color: #d5d5d5;
$slider_bar_bg_color: #eef3f6;
$ls_bar_bg_color: #fafcff;
$menu_hover_color: #cfdbe6;
$input_hover: #f6f8fa;
$selected_bg_color: #4c87e0;
$sub_title_color: #93a9be;
$a_color: #3385ff;
$a_d_color: #d63b1d;
$convert_rem_base: 64rem;

$pct_auto: 100%;
$pct_75: 75%;
$pct_66: 66.66%;
$pct_50: 50%;
$pct_33: 33.33%;
$pct_25: 25%;

$fs_12: 12px;
$fs_14: 14px;
$fs_16: 16px;
$fs_18: 18px;
$fs_24: 24px;

$min_width: 320px;

$fixed_height: 60px;

$border_radius: 2px;

$main_left_width: 200px;

$dialog_z_index: 1000;
$mask_z_index: 100;
$msg_z_index: 99;
$slider_bar_z_index: 98;
$small_z_index: 2;

// 基于ant标准y=8+8n
$big_spacing: 24px;
$middle_spacing: 16px;
$small_spacing: 8px;

// 文字超出部分用省略号代替
.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// input, select的高度
// $is_height: 40px;

// // 透明
// .opacity($opacity) {
//   opacity: $opacity / 100;
//   filter: alpha(opacity=$opacity);
// }

// // 盒子阴影
// .boxShadow($h, $v, $spread, $color) {
//   -webkit-box-shadow: $arguments;
//   -moz-box-shadow: $arguments;
//   -ms-box-shadow: $arguments;
//   -o-box-shadow: $arguments;
//   box-shadow: $arguments;
// }

// .remFontSize($pixel) {
//   font-size: $pixel/64rem;
// }

// .getRem($pixel) {
//   $pixel / 64 rem;
// }

// .calcHeight($height) {
//   height: -moz-calc($height);
//   height: -webkit-calc($height);
//   height: calc($height);
// }

