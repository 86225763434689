.component-province-select {
  width: 100%;
  .select-introduction {
    padding-bottom: 10px;
    .intro-title,
    .intro-content,
    .clear-btn {
      display: inline-block;
      vertical-align: top;
      margin-right: 10px;
    }

    .intro-title {
      width: 50px;
    }

    .clear-btn{
      width: 30px;
      color: #1890ff;
      cursor: pointer;
      margin-right: 0;
    }

    .intro-content {
      position: relative;
      width: calc(100% - 100px);
    }

    .intro-box {
      min-height: 50px;
      line-height: 32px;
      padding: 4px 11px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      &:hover {
        border: 1px solid #1890ff;
      }
      div {
        display: inline-block;
        line-height: 14px;
        margin: 5px;
        background-color: #f0f2f5;
        border-radius: 5px;
        padding: 0 10px;
      }
      span {
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        vertical-align: middle;
      }
      i {
        display: inline-block;
        width: 16px;
        height: 16px;
        line-height: 16px;
        background-color: #c0c4cc;
        color: #fff;
        border-radius: 50%;
        font-size: 10px;
        margin-left: 5px;
        vertical-align: middle;
        cursor: pointer;
        &:hover {
          background-color: #707172;
        }
        svg {
          margin-top: 3px;
        }
      }
    }
  }


  .hide-list {
    display: none;
  }
  .list-box {
    position: absolute;
    left: 0;
    bottom: -400px;
    background-color: #fff;
    z-index: 99;
    .spin-list,
    .list {
      display: inline-block;
      float: left;
      min-width: 120px;
      height: 400px;
      font-size: 14px;
      line-height: 30px;

      .active {
        background-color: #aeb3b7;
        &:hover {
          background-color: #aeb3b7;
        }
      }
    }

    .list {
      overflow-x: hidden;
      overflow-y: scroll;
    }

    .province-item,
    .city-item,
    .district-item {
      padding: 0 10px;
      &:hover {
        background-color: #f0f2f5;
      }
    }

    .partical-select {
      display: inline-block;
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      padding: 3px;
      vertical-align: middle;
      border: 1px solid #d8d5d5;
      border-radius: 2px;
      text-align: center;
      font-size: 0;
      line-height: 16px;
      cursor: pointer;
      span {
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: #1890ff;
      }
    }

    .checkbox {
      display: inline-block;
      width: 16px;
      height: 16px;
      vertical-align: middle;
      .ant-checkbox {
        vertical-align: top;
      }
    }

    .name-mark {
      display: inline-block;
      line-height: 16px;
      vertical-align: middle;
      margin-left: 10px;
      cursor: pointer;
    }

    .right-arrow {
      display: inline-block;
      line-height: 16px;
      vertical-align: middle;
    }
  }

  .province-placeholder {
    line-height: 30px;
    font-size: 14px;
    color: #c1bfbf;
  }
}