.component-pagination {
  padding: 0 20px;
  .introduce,
  .pagination-box {
    display: inline-block;
    vertical-align: middle;
  }
  .introduce {
    font-size: 12px;
    span:first-child {
      margin-right: 20px;
    }
  }

  .pagination-box {
    float: right;
  }
}