@import "./var.scss";
.pg-wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  background-color: #f0f2f5;
}

.pg-left {
  height: 100%;
  position: relative;
  width: $main_left_width;
  left: 0;
  top: 0;
  z-index: 10;
  transition: all 0.5s;
  .left-head {
    height: $fixed_height;
    background: rgba(0, 40, 77, 1);
    line-height: $fixed_height;
  }
  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background-color: #001529;
    box-shadow: none;
  }
  .ant-menu-dark .ant-menu-item,
  .ant-menu-dark .ant-menu-item-group-title,
  .ant-menu-dark .ant-menu-item > a,
  .ant-menu-dark,
  .ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 1);
  }
  .logo-name {
    display: block;
    color: #fff;
    background-size: 25px auto;
    height: 100%;
    white-space: nowrap;
    background: #171f28;
  }
  .pg-left-container {
    padding-top: 20px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: rgba(0, 21, 41, 1);
    user-select: none;
  }
  &.fold {
    .anticon-menu-unfold {
      display: inline-block;
    }
    .anticon-menu-fold {
      display: none;
    }
    width: 0px;
    .logo-name {
      span {
        display: none;
      }
    }
    .ant-menu-item {
      display: none;
    }
    .ant-menu {
      display: none;
    }
    .menu-link {
      span {
        display: none;
      }
      a {
        display: none !important;
      }
    }
    .menu-list-main i {
      display: none;
    }
  }
  .toggle-btn {
    position: absolute;
    font-size: 20px;
    width: 60px;
    z-index: 9;
    white-space: nowrap;
    cursor: pointer;
    text-align: center;
    right: -50px;
  }
  .anticon-menu-unfold {
    display: none;
  }
}

.pg-body {
  overflow-y: auto;
  height: 100%;
  background-color: rgb(240, 242, 245);
  .ant-breadcrumb {
    height: 40px;
    line-height: 40px;
    padding-left: 30px;
    background-color: #fff;
  }
  flex: 1;
}

.pg-body-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.pg-header {
  height: $fixed_height;
  background: #171f28;
  border-bottom: solid 1px #ddd;
  .logo-name,
  .welcome,
  .top-links li {
    height: $fixed_height;
    line-height: $fixed_height;
  }
  .head-right {
    position: relative;
    margin-left: $main_left_width;
  }
  .welcome,
  .top-links {
    position: absolute;
    top: 0;
  }
  .welcome {
    left: 0;
    padding-left: $middle_spacing;
  }
  .top-links {
    top: 20px;
    right: 0;
    li {
      cursor: pointer;
      display: inline-block;
      margin-right: $middle_spacing;
      height: inherit;
      line-height: inherit;
      font-size: $fs_14;
      i {
        margin-right: $small_spacing;
      }
    }
  }
  & + div {
    flex: 1;
    overflow: auto;
  }
}

.menu-list {
  white-space: nowrap;
  .menu-list {
    display: block;
  }
  .menu-parent {
    padding-top: $small_spacing;
    .menu-title {
      padding-left: $small_spacing;
      font-weight: 700;
    }
  }
  .menu-children,
  .menu-link {
    position: relative;
    a,
    .menu-title {
      display: block;
      padding: $small_spacing;
      color: $theme_color;
      font-weight: 400;
      padding-left: 10px;
    }
  }
  .menu-children {
    .btn-add {
      position: absolute;
      top: 2px;
      right: $small_spacing;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: $fs_16;
      cursor: pointer;
    }
  }
  .anticon {
    vertical-align: middle;
    margin-right: 5px;
    font-size: 16px;
  }
}
.main-padding {
  padding: $small_spacing;
}
.menu-list-main {
  i {
    width: 15px;
    height: 15px;
    margin-right: 4px;
  }
  span {
    vertical-align: middle;
  }
  .active {
    color: #fff !important;
    background-color: #1890ff !important;
  }
  .menu-link {
    font-size: 14px;
    color: $theme_color;
    > a {
      padding-left: 20px;
    }
    .menu-title {
      margin: 6px 0;
      > div {
        padding: $small_spacing;
      }
      > a {
        width: 100%;
        display: block;
        padding: $small_spacing;
        color: $theme_color;
      }
    }
    .menu-list-main {
      .menu-link {
        margin: 6px 0;
        a {
          width: 100%;
          display: block;
          padding: $small_spacing;
          padding-left: 24px;
          color: $theme_color;
        }
      }
    }
  }
}
