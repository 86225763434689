
.release-list{
    .ant-table-pagination.ant-pagination{
        float: none;
    }
    .release-list-main{
        padding: 30px;
        margin: 30px;
        background: #ffffff;
        border-radius: 6px;
        .btn{
            text-align: right;
        }
    }
    .ant-tabs{
        width: 100%;
    }
}